import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-tool-page" }
const _hoisted_2 = { class: "login-tool-page-header" }
const _hoisted_3 = { class: "has-text-contents-title has-text-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextAlert = _resolveComponent("TextAlert")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('label.screenTransitioning')), 1)
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createBlock(_component_TextAlert, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('label.screenTransitionFailed')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}