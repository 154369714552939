
import { defineComponent, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { MUTATIONS } from '@/store/mutations-types'
import TextAlert from '@/components/molecules/TextAlert.vue'

export default defineComponent({
  components: {
    TextAlert,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const cardId = route?.query?.cardId ? String(route.query.cardId) : ''
    const password = route?.query?.password ? String(route.query.password) : ''
    const data = new FormData()
    data.append('cardId', cardId)
    data.append('password', password)
    const hasError = computed(() => {
      return store.getters.hasErrorInLoginPage
    })
    const login = async () => { await store.dispatch('builtinLogin', data) }
    login().then(() => {
      if (!hasError.value) {
        store.commit(MUTATIONS.LOGIN_NOTIFICATION)
        router.push('/')
      }
    })

    return {
      hasError,
    }
  },
})
